import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'

import "../../style/Contact.css";
import axios from "axios";

import Swal from "sweetalert2";


function PointTransferredPlayer() {
  let apiBaseURL = "https://royalluck.club:5000";
  //  let apiBaseURL = "http://localhost:5000";

  const [destriData, setDestriData] = useState([]);
  const [values, setValues] = useState({
    id: "",
    points: 0,

  });

  const TokenData = sessionStorage.getItem("token");
  const [isManualInputEnabled, setManualInputEnabled] = useState(false);
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const { id, points } = values;
  //   const data = { id, points };
  //   await fetch(`${apiBaseURL}/user/sendPlayerPoints`, {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${TokenData.token}`,
  //     },
  //     redirect: "follow",
  //     referrerPolicy: "no-referrer",
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status === 200) {
  //         setValues({
  //           id: "",
  //           points: 0,

  //         });
  //         Swal.fire({
  //           position: "top-end",
  //           icon: "success",
  //           title: "Points transfered",
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //       } else {
  //         if (data.status == 401) {
  //           sessionStorage.removeItem("token");
  //           window.location.reload();
  //         } else {
  //           Swal.fire({
  //             position: "top-end",
  //             icon: "warning",
  //             title: "Oops...",
  //             text: `${data.message} !`,
  //             showConfirmButton: false,
  //             timer: 1700,
  //           });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       Swal.fire(`Something Went wrong!`, "error");
  //     });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id, points } = values;
  
    // Check for negative values
    if (points <= 0) {
      Swal.fire({
        icon: "error",
        title: "Invalid Input",
        text: "Please enter a valid positive amount!",
      });
      return;
    }
  
    const data = { id, points };
    await fetch(`${apiBaseURL}/user/sendPlayerPoints`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenData.token}`,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setValues({
            id: "",
            points: 0,
          });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Points transferred",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          if (data.status === 401) {
            sessionStorage.removeItem("token");
            window.location.reload();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Oops...",
              text: `${data.message} !`,
              showConfirmButton: false,
              timer: 1700,
            });
          }
        }
      })
      .catch((error) => {
        Swal.fire(`Something Went wrong!`, "error");
      });
  };
  

  //get Agents
  const getAgents = async () => {
    await axios
    .get(`${apiBaseURL}/user/getPlayerAdmin`)

     // .get(`${apiBaseURL}/user/getPlayer`)
      .then(function (response) {
        if (response.data.status === 200) {
          setDestriData(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`Something Went wrong!`, "error");
      });
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  useEffect(() => {
    getAgents();
  }, []);

  return (
    <div className="container mt-5">
      <div className="borders">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link className="nav-link" to="/pointSuperMaster">
              SuperMaster
            </Link>
          </li>
          <li className="nav-item ml-3">
            <Link className="nav-link" to="/pointMaster">
              MasterId
            </Link>
          </li>
          <li className="nav-item ml-3">
            <Link className="nav-link active" to="/pointPlayer">
              Player
            </Link>
          </li>
        </ul>
      </div>

      <div className="row">
        <div className="col-md-8 mt-8">
          <div className="card">
            <div className="card-header text-center bg-danger text-white">
              <h3>Transfer Points To Player</h3>
            </div>
            <div className="card-body">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="manualInputCheckbox"
                checked={isManualInputEnabled}
                onChange={() => setManualInputEnabled(!isManualInputEnabled)}
              />
              <label className="form-check-label" htmlFor="manualInputCheckbox">
                Enable Manual Input
              </label>
            </div>
            <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Select Player</label>
        {isManualInputEnabled ? (
          <input
            type="text"
            value={values.id}
            onChange={handleChange("id")}
            className="form-control"
            placeholder="Enter Player ID"
          />
        ) : (
          <select
            name="distributor_id"
            onChange={handleChange("id")}
            className="form-control"
            placeholder="Select Player ID"
            disabled={isManualInputEnabled}
          >
            <option value="">Select Player</option>
            {destriData.map((item, index) => (
              <option value={item.email} key={index}>
                {item.email}
              </option>
            ))}
          </select>
        )}
      </div>
                {/* <div className="form-group">
                  <label>Amount To Transfer</label>
                  <input
                    type="number"
                    value={values.points}
                    onChange={handleChange("points")}
                    name="points"
                    className="form-control"
                  />
                </div> */}
<div className="form-group">
  <label>Amount To Transfer</label>
  <input
    type="number"
    value={values.points}
    onChange={handleChange("points")}
    name="points"
    className="form-control"
    min="1" 
  />
</div>


                <div className="form-group text-center">
                  <button type="submit" className="btn btn-success">
                    Send Points
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PointTransferredPlayer
