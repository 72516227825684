import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../style/sideBar.css";
 
function AppSideber() {
  const tokenData = sessionStorage.getItem("token");
 
 
  const [loginEmail, setLoginEmail] = useState(
    sessionStorage.getItem("loginEmail")
  );
  console.log("loginEmail: " + loginEmail);
 
  useEffect(() => {
    const storedLoginEmail = sessionStorage.getItem("loginEmail");
    if (storedLoginEmail !== loginEmail) {
      setLoginEmail(storedLoginEmail);
    }
  }, [loginEmail]);
 
  if (!tokenData) {
    sessionStorage.removeItem("token");
    window.location.reload();
  }
 
  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-3"
      style={{ fontSize: "16px" }}
    >
      <a
  href="index3.html"
  className="brand-link d-flex align-items-center p-3"
>
  <img
    src="logo5.jpg"
    alt="RoyalLuck"
    className="brand-image mr-2"
    style={{
      width: "40px",
      height: "40px",
      objectFit: "cover",
      borderRadius: "50%",
    }}
  />
  <span
    className="brand-text font-weight-bold mb-3 mt-2 ml-3 pl-1"
    style={{ fontSize: "20px", color: "blue" }}
  >
    {loginEmail === "RL0000" ? (
      "RL0000"
    ) : (
      <Link to="/">Admin Panel</Link>
    )}
  </span>
</a>
 
      <div className="sidebar" style={{ overflow: "auto", paddingLeft: "8px" }}>
        <nav className="mt-5">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item menu-open">
              <Link to="/" className="nav-link active">
                <i className="nav-icon fas fa-tachometer-alt custom-icon"></i>
 
                <p>Dashboard</p>
              </Link>
            </li>
            <div
              style={{ display: loginEmail === "RL00000" ? "none" : "block" }}
            >
              <li className="nav-header" style={{ fontSize: "14px" }}>
                Users Management
              </li>
             
              <li className="nav-item">
                <Link to="/AddMaster" className="nav-link">
                  <i className="fa fa-user-plus" aria-hidden="true"></i>
                  <p> Add Super Master </p>
                </Link>
              </li>
              <li className="nav-item" id="Supermaster">
                <Link to="/SuperMaster" className="nav-link">
                  <i class="fa fa-user-secret" aria-hidden="true"></i>
                  <p> View Super Master</p>
                </Link>
              </li>
 
              <li className="nav-item">
                <Link to="/AdMasterId" className="nav-link">
                  <i class="fa fa-user-plus" aria-hidden="true"></i>
                  <p> Add Master Id</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/MasterId" className="nav-link">
                  <i class="fa fa-user-circle" aria-hidden="true"></i>
                  <p> View Master Id</p>
                </Link>
              </li>
 
              <li className="nav-item">
                <Link to="/AddnewPlayer" className="nav-link">
                  <i class="fa fa-user-plus" aria-hidden="true"></i>
                  <p> Add Players</p>
                </Link>
              </li>
 
              <li className="nav-item">
                <Link to="/PlayersList" className="nav-link">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <p> View Players</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/childIds" className="nav-link">
                  <i class="fa fa-users" aria-hidden="true"></i>
                  <p> View Child Ids</p>
                </Link>
              </li>
            </div>
            <div
              style={{ display: loginEmail === "RL00000" ? "none" : "block" }}
            >
              <li className="nav-header" style={{ fontSize: "14px" }}>
                Game histroy
              </li>
              <li className="nav-item">
                <Link to="/AndarBaharGamePlayHistory" className="nav-link">
                  <i className="fas fa-address-book nav-icon"></i>
                  <p>AndarBahar History</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/RoulletGamePlayHistory" className="nav-link">
                  <i class="fas fa-address-book nav-icon "></i>
                  <p>Roullet History</p>
                </Link>
              </li>{" "}
              <li className="nav-item">
                <Link to="/SevenUpGamePlayHistory" className="nav-link">
                  <i class="fas fa-address-book nav-icon"></i>
                  <p>SevenUp History</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/FunTargetGamePlayHistory" className="nav-link">
                  <i class="fas fa-address-book nav-icon"></i>
                  <p>FunTargetHistory</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/TripleChanceGamePlayHistory" className="nav-link">
                  <i class="fas fa-address-book nav-icon"></i>
                  <p>TripleChance History</p>
                </Link>
              </li>
            </div>
            <li className="nav-header" style={{ fontSize: "14px" }}>
              Commission Report
            </li>
            <li className="nav-item">
              <Link to="/CommissionDetail" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p style={{ fontSize: "16px" }}>Commission Details</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/UpcomingCommission" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Upcoming Commission Details</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/check_commission" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Check Commission</p>
              </Link>
            </li>
            <li
              className="nav-item"
              style={{ display: loginEmail === "RL0000" ? "none" : "block" }}
            >
              <Link to="/ShowCurrentBet" className="nav-link">
                <i class="fa fa-gamepad nav-icon"></i>
                <p> Game Controller</p>
              </Link>
            </li>
            {" "}
           
            <li className="nav-header" style={{ fontSize: "14px" }}>
              Point Report
            </li>
            <li
              className="nav-item"
              style={{
                display: loginEmail === "admin@admin.com" ? "none" : "block",
              }}
            >
              <Link to="/manage_points" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Manage Points</p>
              </Link>
            </li>
            <li
              className="nav-item"
              style={{
                display: loginEmail === "admin@admin.com" ? "none" : "block",
              }}
            >
              <Link to="/wallet_transaction" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Wallet Transactions</p>
              </Link>
            </li>
           
            <li
              className="nav-item"
              style={{ display: loginEmail === "RL0000" ? "none" : "block" }}
            >
              <Link to="/PointTransfer" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Credit Points</p>
              </Link>
            </li>
            <li
              className="nav-item"
              style={{ display: loginEmail === "RL00000" ? "none" : "block" }}
            >
              <Link to="/DebitPoint" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Debit Points</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/Transferabled" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Point Transfered</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/PointCancell" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p>Point Cancelled</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/PointReject" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p> Point Rejected</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/PointReceive" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p> Point Received</p>
              </Link>
            </li>
            <li className="nav-header" style={{ fontSize: "14px" }}>
              Location
            </li>
            <li className="nav-item">
              <Link to="/checkLocation" className="nav-link">
                <i className="fa-solid fa-key nav-icon"></i>
                <p>City Name</p>
              </Link>
            </li>
            {/*  <li className="nav-item">
              <Link to="/GameReports" className="nav-link">
                <i className="fa-solid fa-arrow-right-arrow-left nav-icon" />
                <p> Game Report</p>
              </Link>
            </li>
    */}
            <li
              className="nav-header"
              style={{
                display: loginEmail === "RL00000" ? "none" : "block",
                fontSize: "14px",
              }}
            >
              User Settings
            </li>
            <li className="nav-item">
              <Link to="/changepassword" className="nav-link">
                <i className="fa-solid fa-key nav-icon"></i>
                <p>Change Password</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
export default AppSideber;