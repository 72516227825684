import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import { apiBaseURL } from "../../config";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";

// import { authToken } from "../../../authToken";
function TripleChanceGames() {
  const [data, setData] = useState([]);
  const [value1, setvalue1] = useState("")
  const [min, setmin] = useState(0)
  const [max, setmax] = useState(0)
  const [minname, setminname] = useState("")
  const [maxname, setmaxname] = useState("")
  const [detail, setdetail] = useState([]);
  const [andarbaharTimer, setandarbaharTimer] = useState(null);
  const [savedSymbol, setSavedSymbol] = useState(""); // To store saved symbol
  const [savedWinNo, setSavedWinNo] = useState("");   // To store saved win number
  const [symbolSelectedCheckbox, setSymbolSelectedCheckbox] = useState(0); // Example initial value
  const [winNoSelectedCheckbox, setWinNoSelectedCheckbox] = useState(0);
  // get Agents
  // const gameReports = async () => {
  //   await axios
  //     .get(${apiBaseURL}/user/andarbahardetail)
  //     .then(function (response) {
  //       if (response.data.status === 200) { 
  //         setdetail(response.data.data);
  //       }
  //     })
  //     .catch(function (error) { 
  //     });
  // };
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/andarbahardetail`)
      .then(function (response) {
        if (response.data.status === 200) {
          setdetail(response.data.data);
        }
      })
      .catch(function (error) {
      });
  };
  const getTimer = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getAndarBaharTimerValue`);
      if (response.data.timerValue !== undefined) {
        setandarbaharTimer(response.data.timerValue);
      }
    } catch (error) {
      console.error('Error fetching timer value:', error);
    }
  };
  useEffect(() => {

    getTimer();


    const interval = setInterval(() => {
      getTimer();
    }, 500);


    return () => clearInterval(interval);
  }, []);


  const columns = [
    //{ title: "Serial No", render: rowData => rowData.tableData.id + 1 },
    // { title: "Player ID", field: "playername" },
    //{ title: "Round Count", field: "RoundCount" },

    //{ title: "CardResult", field: "Cardresult" },
    //{ title: "FinalResult", field: "finalresult" },
    { title: "Andhar", field: "Card_Andhar_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Bahar	", field: "Card_Bahar_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Heart", field: "Card_Heart_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Diamond", field: "Card_Diamond_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Club", field: "Card_Club_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Spade", field: "Card_Spade_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Red", field: "Card_Red_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Black", field: "Card_Black_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "A_6", field: "Card_A_6_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "Seven", field: "Card_seven_amount", headerStyle: { fontWeight: 'bold' } },
    { title: "8_K", field: "Card_8_K_amount", headerStyle: { fontWeight: 'bold' } },

    // { title: "Card_A_Amount", field: "Card_A_amount"},
    // { title: "Card_2_Amount", field: "Card_2_amount"},
    // { title: "Card_3_Amount", field: "Card_3_amount"},
    // { title: "Card_4_Amount", field: "Card_4_amount"},
    // { title: "Card_5_Amount", field: "Card_5_amount"},
    // { title: "Card_6_Amount", field: "Card_6_amount"},
    // { title: "Card_7_Amount", field: "Card_7_amount"},
    // { title: "Card_8_Amount", field: "Card_8_amount"},
    // { title: "Card_9_Amount", field: "Card_9_amount"},
    // { title: "Card_10_Amount", field: "Card_10_amount"},
    // { title: "Card_J_Amount", field: "Card_J_amount"},
    // { title: "Card_Q_Amount", field: "Card_Q_amount"},
    // { title: "Card_K_Amount", field: "Card_K_amount"},
    // { title: "BetOn0",field:"bet0" },
    // { title: "BetOn1",field:"bet1" },
    // { title: "BetOn2",field:"bet2" },
    // { title: "BetOn3",field:"bet3" },
    // { title: "BetOn4",field:"bet4" },
    // { title: "BetOn5",field:"bet5" },
    // { title: "BetOn6",field:"bet6" },
    // { title: "BetOn7",field:"bet7" },
    // { title: "BetOn8",field:"bet8" },
    // { title: "BetOn9",field:"bet9" },
    // { title: "BetOn10",field:"bet10" },
    // { title: "BetOn11",field:"bet11" },
    // { title: "BetOn12",field:"bet12" },
    // { title: "BetOn13",field:"bet13" },
    // { title: "BetOn14",field:"bet14" },
    // { title: "BetOn15",field:"bet15" },
    // { title: "BetOn16",field:"bet16" },

    // { title: "BetOn17",field:"bet17" },
    // { title: "BetOn18",field:"bet18" },
    // { title: "BetOn19",field:"bet19" },
    // { title: "BetOn20",field:"bet20" },
    // { title: "BetOn21",field:"bet21" },
    // { title: "BetOn22",field:"bet22" },
    // { title: "BetOn23",field:"bet23" },
    // { title: "BetOn24",field:"bet24" },
    // { title: "BetOn25",field:"bet25" },
    // { title: "BetOn26",field:"bet26" },
    // { title: "BetOn27",field:"bet27" },
    // { title: "BetOn28",field:"bet28" },
    // { title: "BetOn29",field:"bet29" },
    // { title: "BetOn30",field:"bet30" },
    // { title: "BetOn31",field:"bet31" },
    // { title: "BetOn32",field:"bet32" },
    // { title: "BetOn33",field:"bet33" },
    // { title: "BetOn34",field:"bet34" },
    // { title: "BetOn35",field:"bet35" },
    // { title: "BetOn36",field:"bet36" },

    // { title: "BetOn37",field:"bet37" },
    // { title: "BetOn38",field:"bet38" },
    // { title: "BetOn39",field:"bet39" },
    // { title: "BetOn40",field:"bet40" },

    // { title: "BetOn41",field:"bet41" },
    // { title: "BetOn42",field:"bet42" },
    // { title: "BetOn44",field:"bet44" },
    // { title: "BetOn44",field:"bet44" },
    // { title: "BetOn45",field:"bet45" },
    // { title: "BetOn46",field:"bet46" },

    // { title: "BetOn47",field:"bet47" },
    // { title: "BetOn48",field:"bet48" },
    // { title: "BetOn49",field:"bet49" },
    // { title: "BetOn50",field:"bet50" },
    // { title: "BetOn51",field:"bet51" },
    //  { title: "BetOn52",field:"bet52" },

    //   { title: "Date & Time", render: rowData => moment(rowData.playedtime).format("DD-MM-YYYY h:mm:ss ") }
  ];

  const columns1 = [
    { title: "Player ID", field: "playername", headerStyle: { fontWeight: 'bold' } },
    { title: "Round Count", field: "RoundCount", headerStyle: { fontWeight: 'bold' } },
    { title: "Andar Bet", field: "BetOnAndar", headerStyle: { fontWeight: 'bold' } },
    { title: "Bahar Bet", field: "BetOnBahar", headerStyle: { fontWeight: 'bold' } },
    { title: "Total Bet", field: "TotalBet", headerStyle: { fontWeight: 'bold' } },
    // { title: "Win Single Number", field: "Win_singleNo" },
    // { title: "Played Time", field: "playedTime", render: rowData => moment(rowData.playedTime).format("DD-MM-YYYY h:mm:ss") }
  ];

  useEffect(() => {
    axios
      .get(`${apiBaseURL}/user/gamerunningandarbahar`)
      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          const result = response.data.data.filter((item) => {
            return item.email != "admin@admin.com";
          });
          var temp = []
          /*     temp.push(result[0])
              temp.push(result[1])
              temp.push(result[result.length-1])
              temp.push(result[result.length-2])
    console.log("temp",temp)
     */
          //setData(result,temp);
          // setData(result);


          setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  }, []);


  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("calling in everyone")
      axios
        // .get(${apiBaseURL}/user/PointHistory)
        // .get(${apiBaseURL}/user/TripleChanceGamePlayHistory)
        .get(`${apiBaseURL}/user/gamerunningandarbahar`)


        .then(function (response) {
          if (response.data.status === 200) {
            console.log(response.data, "data");
            const result = response.data.data.filter((item) => {
              return item.email != "admin@admin.com"

            })
            setData(result);
            console.log(result, "Result")
            setData(result);
            var obj = result[0]
            var name = []
            var arr = Object.keys(obj).map(function (key) {

              if (typeof obj[key] !== 'object' && obj[key] !== 'undefined') {
                name.push(key)
                return obj[key];
              }
              else {
                return null
              }
            });
            console.log(name, "namearr")
            var res = arr.filter(elements => {
              return elements !== null;
            });

            console.log(res, "res")
            var min = Math.min.apply(null, res);
            var max = Math.max.apply(null, res);
            setmin(min)
            setmax(max)
            setminname(name[res.indexOf(min)])
            setmaxname(name[res.indexOf(max)])


            // setData(response.data.data);
          }
        })
        .catch(function (error) {
          // history.push("/login")
        });
    }, 10000);
    gameReports()

    return () => clearInterval(interval);
  }, []);









  useEffect(() => {
    axios
      // .get(${apiBaseURL}/user/PointHistory)
      .get(`${apiBaseURL}/user/getAdminandarbahar`)

      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          // setvalue1(response.data.data.value1)
          //setvalue2(response.data.data.value2)
          //setvalue3(response.data.data.value3)

          //setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  }, [])

  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);

  React.useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  //checkbox controller

  // const [symbolSelectedCheckbox, setSymbolSelectedCheckbox] = useState("");
  // const [winNoSelectedCheckbox, setWinNoSelectedCheckbox] = useState("");

  const handleSymbolCheckboxChange = (value) => {
    if (symbolSelectedCheckbox === value) {
      setSymbolSelectedCheckbox("");
    } else {
      setSymbolSelectedCheckbox(value);
    }
  };

  const handleWinNoCheckboxChange = (value) => {
    if (winNoSelectedCheckbox === value) {
      setWinNoSelectedCheckbox("");
    } else {
      setWinNoSelectedCheckbox(value);
    }
  };

  //clear button
  const handleClearButtonClick = (event) => {
    event.preventDefault();
    setSymbolSelectedCheckbox("");
    setWinNoSelectedCheckbox("");
    //setvalue1(-1)
    axios.post("https://royalluck.club:5000/user/Adminandarbahar", { value: -1 })

      .then((data) => {


        console.log(data);

        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Reset",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // clear button end

  const handleSaveButtonClick = (event) => {
    event.preventDefault();
    var value = 0
    if (symbolSelectedCheckbox == 0) {
      if ((winNoSelectedCheckbox == 0)) {
        value = 39;
      }
      if ((winNoSelectedCheckbox == 1)) {
        value = 40;
      }
      if (winNoSelectedCheckbox == 2) {
        value = 41;
      }
      if (winNoSelectedCheckbox == 3) {
        value = 42;
      }
      if (winNoSelectedCheckbox == 4) {
        value = 43;
      }
      if (winNoSelectedCheckbox == 5) {
        value = 44;
      }
      if (winNoSelectedCheckbox == 6) {
        value = 45;
      }
      if (winNoSelectedCheckbox == 7) {
        value = 46;
      }
      if (winNoSelectedCheckbox == 8) {
        value = 47;
      }
      if (winNoSelectedCheckbox == 9) {
        value = 48;
      }
      if (winNoSelectedCheckbox == 10) {
        value = 49;
      }
      if (winNoSelectedCheckbox == 11) {
        value = 50;
      }
      if (winNoSelectedCheckbox == 12) {
        value = 51;
      }
    }

    if (symbolSelectedCheckbox == 1) {
      if ((winNoSelectedCheckbox == 0)) {
        value = 13;
      }
      if ((winNoSelectedCheckbox == 1)) {
        value = 14;
      }
      if (winNoSelectedCheckbox == 2) {
        value = 15;
      }
      if (winNoSelectedCheckbox == 3) {
        value = 16;
      }
      if (winNoSelectedCheckbox == 4) {
        value = 17;
      }

      if (winNoSelectedCheckbox == 5) {
        value = 18;
      }

      if (winNoSelectedCheckbox == 6) {
        value = 19;
      }
      if (winNoSelectedCheckbox == 7) {
        value = 20;
      }

      if (winNoSelectedCheckbox == 8) {
        value = 21;
      }

      if (winNoSelectedCheckbox == 9) {
        value = 22;
      }

      if (winNoSelectedCheckbox == 10) {
        value = 23;
      }

      if (winNoSelectedCheckbox == 11) {
        value = 24;
      }

      if (winNoSelectedCheckbox == 12) {
        value = 25;
      }
    }

    if (symbolSelectedCheckbox == 2) {
      if ((winNoSelectedCheckbox == 0)) {
        value = 0;
      }
      if ((winNoSelectedCheckbox == 1)) {
        value = 1;
      }
      if ((winNoSelectedCheckbox == 2)) {
        value = 2;
      }
      if (winNoSelectedCheckbox == 3) {
        value = 3;
      }
      if (winNoSelectedCheckbox == 4) {
        value = 4;
      }

      if (winNoSelectedCheckbox == 5) {
        value = 5;
      }

      if (winNoSelectedCheckbox == 6) {
        value = 6;
      }
      if (winNoSelectedCheckbox == 7) {
        value = 7;
      }

      if (winNoSelectedCheckbox == 8) {
        value = 8;
      }

      if (winNoSelectedCheckbox == 9) {
        value = 9;
      }

      if (winNoSelectedCheckbox == 10) {
        value = 10;
      }

      if (winNoSelectedCheckbox == 11) {
        value = 11;
      }

      if (winNoSelectedCheckbox == 12) {
        value = 12;
      }
    }


    if (symbolSelectedCheckbox == 3) {
      if ((winNoSelectedCheckbox == 0)) {
        value = 26;
      }
      if ((winNoSelectedCheckbox == 1)) {
        value = 27;
      }
      if ((winNoSelectedCheckbox == 2)) {
        value = 28;
      }
      if (winNoSelectedCheckbox == 3) {
        value = 29;
      }
      if (winNoSelectedCheckbox == 4) {
        value = 30;
      }

      if (winNoSelectedCheckbox == 5) {
        value = 31;
      }

      if (winNoSelectedCheckbox == 6) {
        value = 32;
      }
      if (winNoSelectedCheckbox == 7) {
        value = 33;
      }

      if (winNoSelectedCheckbox == 8) {
        value = 34;
      }

      if (winNoSelectedCheckbox == 9) {
        value = 35;
      }

      if (winNoSelectedCheckbox == 10) {
        value = 36;
      }

      if (winNoSelectedCheckbox == 11) {
        value = 37;
      }

      if (winNoSelectedCheckbox == 12) {
        value = 38;
      }
    }
    //setvalue1(value)

    axios
      .post("https://royalluck.club:5000/user/Adminandarbahar", { value: value })
      .then((data) => {
        console.log(data);

        // Check if value is set to 37 (for example)
        if (value === 39) {
          setSavedSymbol("Spade A");
        }
        if (value === 40) {
          setSavedSymbol("Spade 2");
        }
        if (value === 41) {
          setSavedSymbol("Spade 3");
        }
        if (value === 42) {
          setSavedSymbol("Spade 4");
        }
        if (value === 43) {
          setSavedSymbol("Spade 5");
        }
        if (value === 44) {
          setSavedSymbol("Spade 6");
        }
        if (value === 45) {
          setSavedSymbol("Spade 7");
        }
        if (value === 46) {
          setSavedSymbol("Spade 8");
        }
        if (value === 47) {
          setSavedSymbol("Spade 9");
        }
        if (value === 48) {
          savedSymbol("Spade 10");
        }
        if (value === 49) {
          setSavedSymbol("Spade Jack");
        }
        if (value === 50) {
          setSavedSymbol("Spade Queen");
        }
        if (value === 51) {
          setSavedSymbol("Spade King");
        }
        if (value === 13) {
          setSavedSymbol("Heart A");
        }
        if (value === 14) {
          setSavedSymbol("Heart 2");
        }
        if (value === 15) {
          setSavedSymbol("Heart 3");
        }
        if (value === 16) {
          setSavedSymbol("Heart 4");
        }
        if (value === 17) {
          setSavedSymbol("Heart 5");
        }
        if (value === 18) {
          setSavedSymbol("Heart 6");
        }
        if (value === 19) {
          setSavedSymbol("Heart 7");
        }
        if (value === 20) {
          savedSymbol("Heart 8");
        }
        if (value === 21) {
          setSavedSymbol("Heart 9");
        }
        if (value === 22) {
          setSavedSymbol("Heart 10");
        }
        if (value === 23) {
          setSavedSymbol("Heart Jack");
        }
        if (value === 24) {
          setSavedSymbol("Heart Queen");
        }
        if (value === 25) {
          setSavedSymbol("Heart King");
        }
        if (value === 0) {
          setSavedSymbol("Diamond A");
        }
        if (value === 1) {
          setSavedSymbol("Diamond 2");
        }
        if (value === 2) {
          setSavedSymbol("Diamond 3");
        }
        if (value === 3) {
          setSavedSymbol("Diamond 4");
        }
        if (value === 4) {
          setSavedSymbol("Diamond 5");
        }
        if (value === 5) {
          setSavedSymbol("Diamond 6");
        }
        if (value === 6) {
          setSavedSymbol("Diamond 7");
        }
        if (value === 7) {
          setSavedSymbol("Diamond 8");
        }
        if (value === 8) {
          setSavedSymbol("Diamond 9");
        }
        if (value === 9) {
          setSavedSymbol("Diamond 10");
        }
        if (value === 10) {
          setSavedSymbol("Diamond Jack");
        }
        if (value === 11) {
          setSavedSymbol("Diamond Queen");
        }
        if (value === 12) {
          setSavedSymbol("Diamond King");
        }
        if (value === 26) {
          setSavedSymbol("Club A");
        }
        if (value === 27) {
          setSavedSymbol("Club 2");
        }
        if (value === 28) {
          setSavedSymbol("Club 3");
        }
        if (value === 29) {
          setSavedSymbol("Club 4");
        }
        if (value === 30) {
          setSavedSymbol("Club 5");
        }
        if (value === 31) {
          setSavedSymbol("Club 6");
        }
        if (value === 32) {
          setSavedSymbol("Club 7");
        }
        if (value === 33) {
          setSavedSymbol("Club 8");
        }
        if (value === 34) {
          setSavedSymbol("Club 9");
        }
        if (value === 35) {
          setSavedSymbol("Club 10");
        }
        if (value === 36) {
          setSavedSymbol("Club Jack");
        }
        if (value === 37) {
          setSavedSymbol("Club Queen");
        }
        if (value === 38) {
          setSavedSymbol("Club King");
        }

        else {
          // Add other symbol updates based on value
        }

        
        // Show success alert
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Admin controller Set",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //checkbox end

  return (
    <div className="col-md-12">
      <div className="card card-outline card-info">
        <div className="borders">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              {/* <span className="ml-3 badge bg-primary">112 </span>{" "}
              <span className="ml-3 badge bg-warning">00:59 </span> */}
              <Link className="nav-link " to="/Triplegame">
                {" "}
                Triple Chance{" "}
              </Link>
            </li>
            <li className="nav-item">
              {/* <span className="ml-3 badge bg-primary">112 </span>{" "}
              <span className="ml-3 badge bg-warning">00:59 </span> */}
              <Link className="nav-link " to="/AndarbaharGame">
                {" "}
                AndarbaharGame{" "}
              </Link>
            </li>

            <li className="nav-item ml-3">
              {/* <span className="badge bg-primary">112 </span>{" "}
              <span className="ml-3 badge bg-warning">00:59 </span> */}
              <Link className="nav-link" to="/JeetoJokerGame">
                {" "}
                Roullet{" "}
              </Link>
            </li>
            <li className="nav-item ml-3">
              {/* <span className="badge bg-primary">112 </span>{" "}
              <span className=" ml-2 badge bg-warning">00:59 </span> */}
              <Link className="nav-link " to="/16CardsGame">
                {" "}
                Sevenup
              </Link>
            </li>
            <li className="nav-item ml-3">
              {/* <span className="badge bg-primary">112 </span>{" "}
              <span className="ml-3 badge bg-warning">00:59 </span> */}
              <Link className="nav-link " to="/SpinWinGame">
                FuntargetGame{" "}
              </Link>
            </li>
          </ul>
        </div>

        <div className="card-body">
          <div className="row">
            {/* First GAme */}
            <div className="col-md-3">
              <div className="card card-outline card-warning">
                <div className="d-flex">
                  <div className="p-2">
                    <h3 className="card-title">AndarBahar Game</h3>
                  </div>
                </div>

                <form className="shadow-sm p-3">
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-start align-items-center">
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "20px" }}
                        onClick={handleClearButtonClick}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-success"
                        onClick={handleSaveButtonClick}
                      >
                        Save
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4" style={{ marginLeft: "10px" }}>
                      {/* Display saved symbol or default "N/A" */}
                      Symbol: {savedSymbol || "N/A"}
                    </div>

                  </div>


                  <div className="row">
                    <div className="col-md-4" style={{ marginLeft: "10px" }}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="symbol"
                          id="symbol0"
                          value="0"
                          checked={symbolSelectedCheckbox === "0"}
                          onChange={() => handleSymbolCheckboxChange("0")}
                        />
                        <label className="form-check-label" htmlFor="symbol0">
                          Spade
                        </label>
                      </div>

                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="symbol"
                          id="symbol1"
                          value="1"
                          checked={symbolSelectedCheckbox === "1"}
                          onChange={() => handleSymbolCheckboxChange("1")}
                        />
                        <label className="form-check-label" htmlFor="symbol1">
                          Heart
                        </label>
                      </div>

                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="symbol"
                          id="symbol2"
                          value="2"
                          checked={symbolSelectedCheckbox === "2"}
                          onChange={() => handleSymbolCheckboxChange("2")}
                        />
                        <label className="form-check-label" htmlFor="symbol2">
                          Diamond
                        </label>
                      </div>

                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="symbol"
                          id="symbol3"
                          value="3"
                          checked={symbolSelectedCheckbox === "3"}
                          onChange={() => handleSymbolCheckboxChange("3")}
                        />
                        <label className="form-check-label" htmlFor="symbol3">
                          Club
                        </label>
                      </div>
                    </div>

                    <div
                      className="col-md-4"
                      style={{ marginLeft: "35px", marginTop: "25px" }}
                    >
                      <div className="form-check pt-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo4"
                          value="0"
                          checked={winNoSelectedCheckbox === "0"}
                          onChange={() => handleWinNoCheckboxChange("0")}
                        />
                        <label className="form-check-label" htmlFor="winNo4">
                          A
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo5"
                          value="1"
                          checked={winNoSelectedCheckbox === "1"}
                          onChange={() => handleWinNoCheckboxChange("1")}
                        />
                        <label className="form-check-label" htmlFor="winNo5">
                          2
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo6"
                          value="2"
                          checked={winNoSelectedCheckbox === "2"}
                          onChange={() => handleWinNoCheckboxChange("2")}
                        />
                        <label className="form-check-label" htmlFor="winNo6">
                          3
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo7"
                          value="3"
                          checked={winNoSelectedCheckbox === "3"}
                          onChange={() => handleWinNoCheckboxChange("3")}
                        />
                        <label className="form-check-label" htmlFor="winNo7">
                          4
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo8"
                          value="4"
                          checked={winNoSelectedCheckbox === "4"}
                          onChange={() => handleWinNoCheckboxChange("4")}
                        />
                        <label className="form-check-label" htmlFor="winNo8">
                          5
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo9"
                          value="5"
                          checked={winNoSelectedCheckbox === "5"}
                          onChange={() => handleWinNoCheckboxChange("5")}
                        />
                        <label className="form-check-label" htmlFor="winNo9">
                          6
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo10"
                          value="6"
                          checked={winNoSelectedCheckbox === "6"}
                          onChange={() => handleWinNoCheckboxChange("6")}
                        />
                        <label className="form-check-label" htmlFor="winNo10">
                          7
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo11"
                          value="7"
                          checked={winNoSelectedCheckbox === "7"}
                          onChange={() => handleWinNoCheckboxChange("7")}
                        />
                        <label className="form-check-label" htmlFor="winNo11">
                          8
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo12"
                          value="8"
                          checked={winNoSelectedCheckbox === "8"}
                          onChange={() => handleWinNoCheckboxChange("8")}
                        />
                        <label className="form-check-label" htmlFor="winNo12">
                          9
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo13"
                          value="9"
                          checked={winNoSelectedCheckbox === "9"}
                          onChange={() => handleWinNoCheckboxChange("9")}
                        />
                        <label className="form-check-label" htmlFor="winNo13">
                          10
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo14"
                          value="10"
                          checked={winNoSelectedCheckbox === "10"}
                          onChange={() => handleWinNoCheckboxChange("10")}
                        />
                        <label className="form-check-label" htmlFor="winNo14">
                          J
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo15"
                          value="11"
                          checked={winNoSelectedCheckbox === "11"}
                          onChange={() => handleWinNoCheckboxChange("11")}
                        />
                        <label className="form-check-label" htmlFor="winNo15">
                          Q
                        </label>
                      </div>
                      <div className="form-check pt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="winNo"
                          id="winNo16"
                          value="12"
                          checked={winNoSelectedCheckbox === "12"}
                          onChange={() => handleWinNoCheckboxChange("12")}
                        />
                        <label className="form-check-label" htmlFor="winNo16">
                          K
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* First End */}

            <div className="col-md-9">
              <h3>Timer: {andarbaharTimer !== null ? andarbaharTimer : "Loading..."}</h3>
              <h4>
                Lowest:{minname}<h5>{min}</h5>

              </h4>
              <h4>
                Highest:{maxname}<h5>{max}</h5>
              </h4>

              <MaterialTable
                title="Current Betting"
                data={data}
                columns={columns}
              />
              <MaterialTable
                title="Current Betting User List"
                data={detail}
                columns={columns1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TripleChanceGames;