import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "../../style/Contact.css";

function PointTransferredSuper() {
  let apiBaseURL = "https://royalluck.club:5000";
  const adminLogin = sessionStorage.getItem("loginEmail");
  const [destriData, setDestriData] = useState([]);
  const [values, setValues] = useState({
    id: "",
    points: 0,
  });
  const [isManualInputEnabled, setManualInputEnabled] = useState(false);
  const TokenData = sessionStorage.getItem("token");

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const { id, points } = values;
  //   const adminLogin = sessionStorage.getItem("loginEmail");

  //   const data = {
  //     sender: adminLogin,
  //     id: id,
  //     points: points,
  //   };

  //   try {
  //     const response = await fetch(`${apiBaseURL}/user/sendSuperMasterPoints`, {

  //     //const response = await fetch(`${apiBaseURL}/user/sendPointsByadmin`, {
  //       method: "POST",
  //       mode: "cors",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${TokenData}`,
  //       },
  //       redirect: "follow",
  //       referrerPolicy: "no-referrer",
  //       body: JSON.stringify(data),
  //     });

  //     const responseData = await response.json();

  //     if (responseData.status === 200) {
  //       setValues({
  //         id: "",
  //         points: 0,
  //       });
  //       Swal.fire({
  //         position: "top-end",
  //         icon: "success",
  //         title: "Points transferred",
  //         showConfirmButton: false,
  //         timer: 1500,
  //       }).then(() => {
  //         window.location.reload();
  //       });
  //     } else {
  //       if (responseData.status === 401) {
  //         sessionStorage.removeItem("token");
  //         window.location.reload();
  //       } else {
  //         Swal.fire({
  //           position: "top-end",
  //           icon: "warning",
  //           title: "Oops...",
  //           text: `${responseData.message} !`,
  //           showConfirmButton: false,
  //           timer: 1700,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     Swal.fire("Something went wrong!", "error");
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id, points } = values;
  
    // Check for invalid values
    if (points <= 0) {
      Swal.fire({
        icon: "error",
        title: "Invalid Input",
        text: "Please enter a valid positive amount!",
      });
      return;
    }
  
    const adminLogin = sessionStorage.getItem("loginEmail");
    const data = {
      sender: adminLogin,
      id: id,
      points: points,
    };
  
    try {
      const response = await fetch(`${apiBaseURL}/user/sendSuperMasterPoints`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TokenData}`,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
  
      if (responseData.status === 200) {
        setValues({
          id: "",
          points: 0,
        });
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Points transferred",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        if (responseData.status === 401) {
          sessionStorage.removeItem("token");
          window.location.reload();
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Oops...",
            text: `${responseData.message} !`,
            showConfirmButton: false,
            timer: 1700,
          });
        }
      }
    } catch (error) {
      console.error(error);
      Swal.fire("Something went wrong!", "error");
    }
  };
  

  // Fetch data on component mount
  useEffect(() => {
    getAgents();
  }, []);

  // Function to fetch agent data
  const getAgents = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getSuperMasterData`);
      if (response.data.status === 200) {
        setDestriData(response.data.data);
      }
    } catch (error) {
      Swal.fire(`Something Went wrong!`, "error");
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  return (
    <div className="container mt-5">
      <div className="borders">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link className="nav-link active" to="/pointSuperMaster">
              SuperMaster
            </Link>
          </li>
          <li className="nav-item ml-3">
            <Link className="nav-link" to="/pointMaster">
              MasterId
            </Link>
          </li>
          <li className="nav-item ml-3">
            <Link className="nav-link" to="/pointPlayer">
              Player
            </Link>
          </li>
        </ul>
      </div>

      <div className="row">
        <div className="col-md-8 mt-8">
          <div className="card">
            <div className="card-header text-center bg-primary text-white">
              <h3>Transfer Points To Super Master</h3>
            </div>
            <div className="card-body">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="manualInputCheckbox"
                  checked={isManualInputEnabled}
                  onChange={() => setManualInputEnabled(!isManualInputEnabled)}
                />
                <label className="form-check-label" htmlFor="manualInputCheckbox">
                  Enable Manual Input
                </label>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Select </label>
                  {isManualInputEnabled ? (
                    <input
                      type="text"
                      value={values.id}
                      onChange={handleChange("id")}
                      className="form-control"
                      placeholder="Enter Super Master ID"
                    />
                  ) : (
                    <select
                      name="distributor_id"
                      onChange={handleChange("id")}
                      className="form-control custom-dropdown"
                      placeholder="Select Super master"
                      disabled={isManualInputEnabled}
                    >
                      <option value="">Select Super master</option>
                      {destriData.map((item, index) => (
                        <option value={item.email} key={index}>
                          {item.email}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                {/* <div className="form-group">
                  <label>Amount To Transfer</label>
                  <input
                    type="number"
                    value={values.points}
                    onChange={handleChange("points")}
                    name="points"
                    className="form-control"
                  />
                </div> */}
                <div className="form-group">
  <label>Amount To Transfer</label>
  <input
    type="number"
    value={values.points}
    onChange={handleChange("points")}
    name="points"
    className="form-control"
    min="1" 
  />
</div>


                <div className="form-group text-center">
                  <button type="submit" className="btn btn-success">
                    Send Points
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PointTransferredSuper;
