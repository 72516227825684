import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import Swal from "sweetalert2";
import "../../style/Contact.css"; // Ensure this file is present and contains necessary styles.
const MasterIdList = () => {
  return (
    <div style={{ fontSize: "18px", fontWeight: "bold" }}>Master ID List</div>
  );
};
function ViewMasterId() {
  const apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [error, setError] = useState("");
  const getPlayers = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/user/getMasterIdData`);
      if (response.data.status === 200) {
        setData(response.data.data);
      } else {
        setError("Error fetching Master IDs");
      }
    } catch (err) {
      console.error(err);
      setError("Error fetching Master IDs");
    }
  };
  useEffect(() => {
    getPlayers();
  }, []);
  const handleEdit = async (rowData) => {
    const { id, commission: currentCommission, password, supermastername } = rowData;
    const newCommission = prompt(`Enter new commission (Current: ${currentCommission}%)`);
    if (newCommission === null) return;
    if (isNaN(newCommission) || newCommission < 0 || newCommission > 100) {
      Swal.fire("Error", "Commission must be between 0 and 100", "error")
      return;
    }
 
    const newPassword = prompt("Enter new password (leave blank to keep current password)");
    const superMasterId = prompt(
      `Enter Super Master ID (Current: ${supermastername || "None"})`
    );
    if (!superMasterId) {
      Swal.fire("Error", "Super Master ID is required", "error");
      return;
    }
    try {
      const payload = {
        id,
        commission: newCommission,
        password: newPassword || password,
        superMasterId,
      };
      const response = await axios.put(`${apiBaseURL}/user/updateMasterId`, payload);
      if (response.status === 200) {
        Swal.fire("Success", `Master ID ${rowData.email} updated successfully`, "success").then(getPlayers);
      } else {
        Swal.fire("Error", response.data.message, "error");
      }
    } catch (error) {
      console.error("Error updating MasterId:", error);
      Swal.fire("Error", "Something went wrong", "error");
    }
  };
  const handleDelete = (email) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `${apiBaseURL}/user/deleteMasterId`,
            { data: { email } }
          );
          if (response.status === 200) {
            Swal.fire("Deleted!", "MasterId has been deleted.", "success");
            getPlayers();
          } else {
            Swal.fire("Error", response.data.message, "error");
          }
        } catch (error) {
          console.error("Error deleting MasterId:", error);
          Swal.fire("Error", "Something went wrong", "error");
        }
      }
    });
  };
  const ViewMasterFunction = async (email) => {
    try {
      const response = await axios.post(`${apiBaseURL}/user/getEmailsById`, {
        email,
      });
      const { masteridUsers, users } = response.data.result;
      const tableRows = users
        .map(
          (user) =>
            `<tr><td>${user.email}</td><td>${user.password}</td></tr>`
        )
        .join("");
      Swal.fire({
        icon: "success",
        title: "Child Master IDs and Passwords",
        html: `<div style="background-color: white; color: black;">
                <table style="width:100%">
                  <tr>
                    <th>Email</th>
                    <th>Password</th>
                  </tr>
                  ${tableRows}
                </table>
              </div>`,
        customClass: {
          title: "swal-title",
          content: "swal-text",
        },
        iconHtml: '<i class="fa-solid fa-user-friends"></i>',
      });
    } catch (error) {
      setError("Error retrieving IDs. Please try again.");
    }
  };
  const columns = [
    { title: "Sl No.", field: "id", headerStyle: { fontSize: "16px" } },
    { title: "Master ID", field: "email", headerStyle: { fontSize: "16px" } },
    { title: "Point", field: "point", headerStyle: { fontSize: "16px" } },
    {
      title: "Super Master Name",
      field: "supermastername",
      headerStyle: { fontSize: "16px" },
    },
    { title: "Commission %", field: "commission", headerStyle: { fontSize: "16px" } },
    { title: "Password", field: "password", headerStyle: { fontSize: "16px" } },
    {
      title: "Actions",
      headerStyle: { fontSize: "18px" },
      render: (rowData) => (
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: "10px" }}>
          <button
            className="btn btn-danger"
            onClick={() => handleEdit(rowData)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger"
            onClick={() => handleDelete(rowData.email)}
          >
            Delete
          </button>
          <button
            className="btn btn-primary"
            onClick={() => ViewMasterFunction(rowData.email)}
          >
            View IDs
          </button>
        </div>
      ),
    },
 
  ];
  return (
    <div className="card card-outline card-info">
      <MaterialTable
        title={<MasterIdList />}
        data={data}
        columns={columns}
        options={{ actionsColumnIndex: -1 }}
      />
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
}
 
export default ViewMasterId;